/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Furqan Ahmed",
  logo_name: "Furqan AHmed",
  nickname: "nblogist",
  subTitle:
    "A passionate individual who loves to tour & program, who always thrive to work on emerging technologies space especially in substrate to create impact in the web 3.0 revolution.",
  resumeLink:
    "https://cdn.flow.page/images/8839c5c5-09d3-4e6f-8871-a2c2ebaa855c-pdf?m=1611998446",
  portfolio_repository: "https://github.com/nblogist/",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"
  {
    name: "Calendly",
    link: "https://calendly.com/furqanahmed",
    fontAwesomeIcon: "far fa-calendar-plus", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#4285F4", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Github",
    link: "https://github.com/nblogist",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/furqanAhmed18",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Telegram",
    link: "https://t.me/FurqanAhmed18",
    fontAwesomeIcon: "fa-telegram", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:hello@furqan.me",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Blockchain",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing Substrate Runtime",
        "⚡ Designing Substrate Pallets",
        "⚡ Developing contracts on Ink!, an eDSL based on Rust",
        "⚡ Blockchain Solution Integrations",
        "⚡ Blockchain Consultation",
      ],
      softwareSkills: [
        // {
        //   skillName: "Solidity",
        //   fontAwesomeClassname: "logos-ethereum",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#D00000",
        //   },
        // },
        {
          skillName: "Substrate",
          fontAwesomeClassname: "simple-icons:paritysubstrate",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Rust",
          fontAwesomeClassname: "logos-rust",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing Blockchain Backends in Substrate",
        "⚡ Developing application backends in Node & Express",
        `⚡ Developing fullstack applications`,
        "⚡ Building resposive frontends for different web apps in ReactJS & MithrilJS",
        `⚡ Developing REST APIs using ExpressJS, SequelizeJS, PostgreSQL`,
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "logos-typescript",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "MithrilJS",
          fontAwesomeClassname: "logos-mithril",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "ExpressJS",
          fontAwesomeClassname: "logos-express",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Sequelize",
          fontAwesomeClassname: "logos-sequelize",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Truffle",
          fontAwesomeClassname: "file-icons:truffle",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Mocha",
          fontAwesomeClassname: "logos-mocha",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "logos-git",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos-postgresql",
          style: {
            color: "#339933",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Substrate Developer Academy",
      iconifyClassname: "simple-icons:paritysubstrate",
      style: {
        color: "#2EC866",
      },
      profileLink:
        "https://industryconnect.io/Authority/ViewCertificate?guid=897ba499-24c8-4cbd-94ae-73a052458cec",
    },
    {
      siteName: "Udemy",
      iconifyClassname: "logos-udemy",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://udemy.com",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Usman Institute of Technology ( NED University ) ",
      subtitle: "BS. Software Engineering (CGPA: 3.8/4.0)",
      logo_path: "uit_logo.png",
      alt_name: "UIT",
      duration: "",
      descriptions: [
        "⚡ Graduated 3rd top of the entire batch! 🎉",
        "⚡ I have studied software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Blockchain, Rust, Cloud Computing and Backend Development.",
        "⚡ Have been chair and vice chainman for IEEE chapter of UIT, have been a team lead in ACM chapter",
      ],
      website_link: "http://uit.edu",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "",
    //   subtitle: "- ",
    //   logo_path: "??.png",
    //   certificate_link:
    //     "",
    //   alt_name: "",
    //   color_code: "#",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work",
  description:
    "I have worn several hats while working with startups and established companies ranging from consultation & advice-assist roles to full stack developer and everything in between, all of the companies that I worked with, were either Blockchain based or moving towards Blockchain in one way or another, I have also established Blockchain departments for few companies, including mentoring new hires. I love meeting new people and working with diverse teams.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Software Engineer - Community Developer",
          company: "Edgeware Builders Guild",
          company_url: "https://github.com/edgeware-builders",
          logo_path: "edgeware-builders-guild.jpeg",
          duration: "Sept 2020 - Present",
          location: "Remote",
          description: `Contribute in different projects being built around Edgeware (www.edgewa.re).`,
          color: "#0879bf",
        },
        {
          title: "Open Source Contributor",
          company: "Edgeware - Darwinia - YieldScan - Subscan",
          company_url: "https://github.com/nblogist",
          logo_path: "github_logo.png",
          duration: "Jan 2020 - Present",
          location: "Remote",
          description: `• Edgeware - A high-performance, self-upgrading WASM smart contract platform, in the Polkadot
            ecosystem
            • Darwinia - An open cross-chain bridge protocol based on Substrate
            • YieldScan - Removing information asymmetry in staking within Polkadot ecosystem
            • Subscan - A blockchain explorer built for Substrate based networks`,
          color: "#9b1578",
        },
        {
          title: "Software Engineer (Blockchain)",
          company: "Seedcode",
          company_url: "https://seedcode.io/",
          logo_path: "seedcode.webp",
          duration: "June 2020 - Dec 2020",
          location: "Remote - Karachi, Pakistan",
          description: `
            • Developed REST APIs using ExpressJS, SequelizeJS, PostgreSQL
            • Developed complete applications from scratch
            • Worked on PolkadotJS, Substrate, Solidity and Edgeware
            • Created a Beresheet Testnet Faucet for Edgeware Testnet
            • Made front-end for different projects on MithrilJS
            • Extensively Tested code on Mocha
            • Deploying on AWS EC2
            • Mentoring New Hires in Blockchain Department
            `,
          color: "#0879bf",
        },
        {
          title: "Blockchain Solution Consultant",
          company: "ROSETTA - Usman Institute of Technology, etc",
          company_url: "https://www.linkedin.com/in/furqanAhmed18/",
          logo_path: "self.svg",
          duration: "Jan 2019 - Present",
          // location: "R",
          description: `Provided Consultation on connecting Legacy DM Software with Blockchain - 
            Provided Consultation developing UIT ERC20 Token to be used on campus, provided consultation for other usecases for implementing and integrating Blockchain solutions in different exisisting and new systems`,
          color: "#fc1f20",
        },
        {
          title: "Solidity Developer",
          company: "Self - Freelance",
          company_url: "https://www.linkedin.com/in/furqanAhmed18/",
          logo_path: "self.svg",
          duration: "May 2017 – Present",
          location: "Remote",
          description: `• CLP Token
            • Multiple Ethereum based games and Contracts in Solidity`,
          color: "#fc1f20",
        },
        {
          title: "Blockchain Short Course Instructor",
          company: "ROSETTA - Usman Institute of Technology, etc",
          company_url: "https://www.linkedin.com/in/furqanAhmed18/",
          logo_path: "uit_logo.png",
          duration: "Jan 2019 - Present",
          location: "Karachi",
          description: `• Conducted Short course introducing Blockchain
            • It was designed to take someone who is new to Blockchain to someone who is able to write basic smart
            contracts in Solidity.`,
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Talks",
      experiences: [
        {
          title: "Road Map to Blockchain - Polkadot, Substrate",
          company: "Google Student Developer Club",
          company_url:
            "https://dsc.community.dev/events/details/developer-student-clubs-usman-institute-of-technology-presents-road-map-to-blockchain/#/",
          logo_path: "google_developers_club_logo.svg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "Got invited by the Google Student Developer Club to talk about getting into newer blockchain technologies and contributing to the open source projects and applying for grants",
          color: "#0071C5",
        },
        {
          title: "Blockchain 101",
          company: "Usman Institute of Technology",
          company_url: "https://www.uit.edu/",
          logo_path: "uit_logo.png",
          duration: "November 2017",
          location: "Karachi, Pakistan",
          description:
            "Got invited by the Usman Institute of Technology Management to introduce the student body to the basics of Blockchain and help them get started",
          color: "#ee3c26",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "acm",
    //       company: "ACM",
    //       company_url: "",
    //       logo_path: ".png",
    //       duration: "",
    //       location: "",
    //       description:"",
    //       color: "#",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest tools. My best experience is to work on blockchain based projects & tools and to deploy them to web applications using cloud infrastructure; or just contributing to an existing project. I have fallen in love with Substrate and now I am razor focused on building Substrate based backends for Blockchains",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "furqan.png",
    description:
      "I am available on the following social media. You can book a slot with me from the calendar link below. I can help you with Blockchain technologies & Opensource Development. I prefer working remote",
  },
  blogSection: {
    // title: "Blogs",
    // subtitle:
    //   "",
    // link: "",
    // avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Karachi, Sindh, Pakistan",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/jRC57ScDHmdQ1Bk5A",
  },
  phoneSection: {
    // title: "Phone Number",
    // subtitle:,
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
